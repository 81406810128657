import { FC } from "react";

const HeutigeMenus: FC = () => (
  <div className={"flex flex-row hdhoch:mt-0 wqhdhoch:mt-0 hdquer:mt-8"}>
    <h1
      className={
        "bg-white hdhoch:text-7xl hdquer:text-5xl wqhdhoch:text-9xl wqhdhoch:px-20 hdhoch:px-12 wqhdhoch:py-12 hdhoch:py-4 font-bold"
      }
    >
      MENÜS
    </h1>
  </div>
);

export default HeutigeMenus;
