import React from "react";
import basilikum from "../assets/basilikum.svg";

const Basilikum = () => (
  <img
    src={basilikum}
    alt={"basilikum"}
    className={
      "fixed hdhoch:-top-[100px] wqhdhoch:-top-[300px] hdquer:-top-[200px] hdhoch:-right-[40px] wqhdhoch:-right-[60px] hdhoch:w-[360px] wqhdhoch:w-[700px]"
    }
  />
)


export default Basilikum;