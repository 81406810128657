import React, { Fragment, lazy, Suspense } from "react";
import { useSettings } from "./providers/SettingsProvider";
import gourmetta from "./assets/gourmetta_transparent_white.svg";
import QRCode from "./components/QRCode";
import Logo from "./components/Logo";
import Information from "./components/Information";
import MenuProvider from "./providers/MenuProvider";
import Pagewrapper from "./components/pagewrapper";
import ArtikelProvider from "./providers/ArtikelProvider";
import Aktion from "./Aktion";
import MenuVorschau from "./MenuVorschau";

const MenusPreisliste = lazy(() => import("./MenusPreisliste"));
const VytalFlyer = lazy(() => import("./VytalFlyer"));
const Artikel = lazy(() => import("./Artikel"));
const Menus = lazy(() => import("./Menus"));
const MenusHalf = lazy(() => import("./MenusHalf"));

function App() {
  const settings = useSettings();

  const renderRole = (): JSX.Element => {
    if (!settings.settings) return <div>Settings error!</div>;
    switch (settings.settings.role) {
      case "Menüs": {
        return (
          <MenuProvider>
            <Menus />
          </MenuProvider>
        );
      }
      case "Menüs 1&2":
      case "Menüs 3&4": {
        return (
          <MenuProvider>
            <MenusHalf />
          </MenuProvider>
        );
      }
      case "Menü Vorschau": {
        return (
          <MenuProvider>
            <MenuVorschau />
          </MenuProvider>
        );
      }
      case "Menüs & Preisliste": {
        return (
          <MenuProvider>
            <ArtikelProvider>
              <MenusPreisliste />
            </ArtikelProvider>
          </MenuProvider>
        );
      }
      case "Vytal Flyer": {
        return (
          <Suspense fallback={"...Loading"}>
            <VytalFlyer />
          </Suspense>
        );
      }
      case "Preisliste": {
        return (
          <ArtikelProvider>
            <Artikel />
          </ArtikelProvider>
        );
      }
      case "Aktion": {
        return <Aktion />;
      }
      default: {
        return <div>Rolle nicht zuzuordnen</div>;
      }
    }
  };

  return (
    <Pagewrapper>
      {settings.error && (
        <div className={"flex flex-row justify-center items-center w-full h-full"}>
          <img src={gourmetta} alt={"gourmetta"} className={"w-1/2"} />
        </div>
      )}
      <main>{!settings.loading && settings.ready && renderRole()}</main>
      {typeof settings.settings !== "undefined" && settings.settings.role !== "Vytal Flyer" && (
        <Fragment>
          <QRCode />
          <Logo />
          <Information />
        </Fragment>
      )}
    </Pagewrapper>
  );
}

export default App;
