import React, {createContext, useContext, useEffect, useState, Suspense, useMemo} from "react";
import axios from "axios";
import {useSettings} from "./SettingsProvider";

interface CategoryEntity {
  uuid: string;
  name: string;
  created: string;
  updated: string;
  deleted: string;
}

export interface Artikel {
  uuid: string;
  title: string;
  description: string | null;
  price: number | null;
  category: CategoryEntity;
  created: string;
  updated: string;
}

interface IUseArtikelProvider {
  artikel: Artikel[];
  loading: boolean;
  pages: { page: number; artikel: Artikel[] }[];
  page: number;
}

const getArtikelHeight = (dimensions: { width: number; height: number }) => {
  if(dimensions.width === 1080 && dimensions.height === 1920){
    return 108;
  }
  if(dimensions.width === 1920 && dimensions.height === 1080){
    return 100;
  }
  if(dimensions.width === 2160 && dimensions.height === 3840){
    return 152;
  }
  if(dimensions.width === 3840 && dimensions.height === 2160){
    return 200;
  }
  return 0;
};
const getHeaderHeight = (dimensions: { width: number; height: number }) => {
  if(dimensions.width === 1080 && dimensions.height === 1920){
    return 76;
  }
  if(dimensions.width === 1920 && dimensions.height === 1080){
    return 100;
  }
  if(dimensions.width === 2160 && dimensions.height === 3840){
    return 128;
  }
  if(dimensions.width === 3840 && dimensions.height === 2160){
    return 200;
  }
  return 0;
};
const getHeaderHeightExtra = (dimensions: { width: number; height: number }) => {
  if(dimensions.width === 1080 && dimensions.height === 1920){
    return 48;
  }
  if(dimensions.width === 1920 && dimensions.height === 1080){
    return 48;
  }
  if(dimensions.width === 2160 && dimensions.height === 3840){
    return 48;
  }
  if(dimensions.width === 3840 && dimensions.height === 2160){
    return 48;
  }
  return 0;
};

const windowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  }
}

const ArtikelContext = createContext({} as IUseArtikelProvider);
export const useArtikel = () => useContext(ArtikelContext);

const useArtikelProvider = () => {
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [artikel, setArtikel] = useState<Artikel[]>([]);
  const [page, setPage] = useState<number>(0);
  const [dimensions, setDimensions] = useState<{ width: number; height: number}>(windowDimensions());

  const height = useMemo(() => getArtikelHeight(dimensions), [dimensions]);
  const headerHeight = useMemo(() => getHeaderHeight(dimensions), [dimensions]);
  const headerHeightExtra = useMemo(() => getHeaderHeightExtra(dimensions), [dimensions]);
  const pageHeight = useMemo(() => {
    if(dimensions.height === 1080){
      return 740;
    }
    if(dimensions.height === 1920){
      return 1580;
    }
    if(dimensions.height === 2160){
      return 1600;
    }
    if(dimensions.height === 3840){
      return 3280;
    }
    return 0;
  }, [dimensions.height])
  const settings = useSettings();

  const pages = useMemo(() => {
    const returnPages: { page: number; artikel: Artikel[] }[]= [];

    for(let i = 0; i < artikel.length; i++){
      if(i === 0){
        returnPages.push({
          page: 0,
          artikel: [artikel[i]]
        })
        continue;
      }

      const currentPageArtikel = returnPages[returnPages.length - 1].artikel;
      let currentPageHeight = 0;

      for(let x = 0; x < currentPageArtikel.length; x++){
        if(x === 0){
          currentPageHeight += headerHeight + height;
        }
        else if(currentPageArtikel[x].category.uuid !== currentPageArtikel[x - 1].category.uuid){
          currentPageHeight += headerHeight + height + headerHeightExtra;
        }
        else {
          currentPageHeight += height;
        }
      }

      let addHeight = height;
      if(artikel[i].category.uuid !== artikel[i - 1].category.uuid){
        addHeight += headerHeightExtra + headerHeight;
      }

      if(currentPageHeight + addHeight <= pageHeight){
        returnPages[returnPages.length - 1].artikel.push(artikel[i]);
      }
      else {
        returnPages.push({
          page: returnPages.length - 1,
          artikel: [artikel[i]]
        })
      }
    }

    return returnPages;
  }, [artikel, headerHeight, headerHeightExtra, height, pageHeight])

  useEffect(() => {
    let cancel = false;

    if(!settings.error && !settings.loading && settings.ready && settings.uuid){
      axios.get<Artikel[]>("/display-artikel?uuid=" + settings.uuid).then((response) => {
        if(!cancel){
          setArtikel(response.data);
          setLoading(false);
          setError(false);
        }
      }).catch(() => {
        if(!cancel){
          setError(true);
          setLoading(false);
        }
      })
    }

    return () => {
      cancel = true;
    }
  }, [settings.error, settings.uuid, settings.loading, settings.ready])

  useEffect(() => {
    const handleResize = () => {
      setDimensions(windowDimensions())
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      if(pages.length > 1){
        if(pages.length === page + 1){
          setPage(0);
        }
        else {
          setPage(page + 1);
        }
      }
    }, 12000)

    return () => clearInterval(timer);
  }, [page, pages.length])



  return {
    error,
    loading,
    artikel,
    pages,
    page,
  }
}
const ArtikelProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const artikel = useArtikelProvider();
  return <ArtikelContext.Provider value={artikel}>
    <Suspense fallback={"...Loading"}>
      {children}
    </Suspense>
  </ArtikelContext.Provider>
}

export default ArtikelProvider;