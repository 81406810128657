import { FC, ReactNode } from "react";

const MealsWrapper: FC<{ children?: ReactNode }> = ({ children }) => (
  <div
    className={
      "flex flex-col w-full wqhdhoch:gap-24 wqhdquer:gap-12 hdhoch:gap-10 hdquer:gap-6 text-white wqhdhoch:mt-24 hdhoch:mt-12"
    }
  >
    {children}
  </div>
);

export default MealsWrapper;
