import React, {createContext, useContext, useEffect, useState, Suspense} from "react";
import {useSettings} from "./SettingsProvider";
import axios from "axios";

export interface Menu {
  uuid: string;
  created: string;
  date: string;
  description: string;
  isTopMeal: boolean;
  mealId: number;
  price: number | null;
  title: string;
  allergens: string | null;
  updated: string;
  deg: boolean;
}

interface IUseMenuProvider {
  menus: Menu[];
  loading: boolean;
  error: boolean;
  tagesmenu: Menu | undefined;
}

const MenuContext = createContext({} as IUseMenuProvider);
export const useMenu = () => useContext(MenuContext);

const useMenuProvider = () => {
  const [menus, setMenus] = useState<Menu[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const settings = useSettings();
  const [tagesmenu, setTagesmenu] = useState<Menu | undefined>(undefined);

  useEffect(() => {
    let cancel = false;
    if(!settings.loading && settings.ready && settings.uuid){
      if(!cancel){
        setLoading(true);
      }
      axios.get<Menu[]>("/" + settings.uuid + "/meals").then((response) => {
        if(!cancel){
          let i = response.data.length;
          while(i--){
            if(response.data[i].isTopMeal){
              setTagesmenu(response.data[i]);
              break;
            }
          }
          setMenus(response.data);
          setLoading(false);
        }
      }).catch((error) => {
        console.log(error.response.data);
        setError(true);
        setLoading(false);
      })
    }

    return () => {
      cancel = true;
    }
  }, [settings.uuid, settings.loading, settings.ready])

  return {
    menus,
    error,
    loading,
    tagesmenu,
  }
}

const MenuProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const menu = useMenuProvider();
  return <MenuContext.Provider value={menu}>
    <Suspense fallback={"...Loading"}>
      {children}
    </Suspense>
  </MenuContext.Provider>
}

export default MenuProvider;