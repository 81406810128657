import { FC } from "react";
import { useMenu } from "./providers/MenuProvider";
import vegan from "./assets/Vegan_Icon.png";
import MealsWrapper from "./components/MealsWrapper";
import Meal from "./components/Meal";
import HeutigeMenus from "./components/HeutigeMenus";
import Basilikum from "./components/basilikum";
import Bowl from "./components/bowl";

const MenuVorschau: FC = () => {
  const menu = useMenu();

  return (
    <div className="wqhdhoch:py-24 wqhdhoch:px-32 hdhoch:p-24 hdquer:px-40 hdquer:py-4 flex flex-col h-full">
      <Bowl />
      <Basilikum />
      <HeutigeMenus />
      <MealsWrapper>
        {menu.menus.map((menu, index) => (
          <Meal key={index} menu={menu} />
        ))}
      </MealsWrapper>
      <img
        src={vegan}
        alt="vegan icon"
        className="absolute wqhdhoch:bottom-96 wqhdhoch:right-12 hdhoch:bottom-52 hdhoch:right-12 hdhoch:w-56 hdquer:w-56 wqhdhoch:w-auto wqhdquer:w-auto"
      />
    </div>
  );
};

export default MenuVorschau;
