import React, {createContext, useContext, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import axios from "axios";

interface Settings {
  width: number;
  height: number;
  role: string;
  name: string;
}

interface IUseSettingsProvider {
  settings: Settings | undefined;
  loading: boolean;
  ready: boolean;
  error: boolean;
  uuid: string | undefined;
}

const SettingsContext = createContext({} as IUseSettingsProvider);
export const useSettings = () => useContext(SettingsContext);
const useSettingsProvider = () => {
  const [settings, setSettings] = useState<Settings | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useSearchParams();
  const [error, setError] = useState<boolean>(false);
  const [uuid, setUuid] = useState<string | undefined>(undefined);

  useEffect(() => {
    let cancel = false;
    const searchUuid = search.get("uuid");

    if(searchUuid){
      setLoading(true);
      try {
        setUuid(searchUuid);
        axios.get<Settings>("/" + searchUuid + "/settings").then((response) => {
          if(!cancel){
            setSettings(response.data);
            setError(false);
            setReady(true);
            setLoading(false);
          }
        }).catch((error) => {
          console.log(error.response.data);
          if(!cancel){
            setLoading(false);
            setReady(false);
            setError(true);
          }
        })
      } catch {
        setError(true);
        setLoading(false);
        setReady(false);
      }
    } else {
      if(!cancel){
        setReady(false);
        setError(true);
      }
    }

    return () => {
      cancel = true;
    }
  }, [search])

  return {
    settings,
    loading,
    ready,
    error,
    uuid,
  }
}

const SettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const settings = useSettingsProvider();
  return <SettingsContext.Provider value={settings}>
    {children}
  </SettingsContext.Provider>
}

export default SettingsProvider;