import React, {Fragment, useEffect, useState} from "react";
import {useSettings} from "../providers/SettingsProvider";
import axios from "axios";

const QRCode: React.FC = () => {
  const [data, setData] = useState<string>("");
  const settings = useSettings();

  useEffect(() => {
    let cancel = false;

    if(!settings.loading && settings.ready && settings.uuid){
      axios.get<string>("/" + settings.uuid + "/qrcode").then((response) => {
        if(!cancel){
          setData("data:image/png;base64," + response.data);
        }
      }).catch((error) => {
        console.log(error.response.data);
      })
    }

    return () => {
      cancel = true;
    }
  }, [settings.uuid, settings.loading, settings.ready])

  return (
    <Fragment>
      <p className={"z-20 text-white fixed wqhdhoch:left-24 hdhoch:bottom-[300px] wqhdhoch:bottom-[500px] hdhoch:left-12 hdhoch:text-[22px] wqhdhoch:text-[45px] m-0"}>Sag uns deine Meinung!</p>
      <img className={"z-20 fixed hdhoch:w-[240px] wqhdhoch:w-[400px] hdhoch:h-[240px] wqhdhoch:h-[400px] object-contain hdhoch:bottom-12 wqhdhoch:bottom-24 wqhdhoch:left-24 hdhoch:left-12 bg-no-repeat"} src={data}  alt={"qr code"}/>
    </Fragment>
  )
}

export default QRCode;