import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import SettingsProvider from "./providers/SettingsProvider";

export const formatEuro = (price: number) => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price);

axios.defaults.baseURL =
  process.env.NODE_ENV === "development" ? "http://localhost:3333/digitalsignage" : "https://api.gourmetta.de/digitalsignage";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <SettingsProvider>
        <App />
      </SettingsProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);