import React from "react";
import deg from "../assets/deg.png";

const MealDeg = () => (
  <img
    src={deg}
    alt={"deg"}
    className={"hdquer:w-12 hdhoch:w-20 wqhdquer:w-20 wqhdhoch:w-20 absolute bottom-0 right-0"}
  />
)

export default MealDeg;