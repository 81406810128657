import { FC } from "react";
import { formatEuro } from "..";
import { Menu } from "../providers/MenuProvider";
import MealDeg from "./meal-deg";

const Meal: FC<{ menu: Menu, hideTag?: boolean }> = ({ menu, hideTag }) => (
  <div className={"flex flex-nowrap flex-row w-full gap-4 hdhoch:gap-12 wqhdquer:gap-12 hdquer:gap-12 relative"}>
    {!hideTag && (<h1 className={"wqhdhoch:text-8xl hdhoch:text-5xl hdquer:text-5xl font-bold"}>M{menu.mealId}</h1>)}
    <div className={"flex flex-col grow wqhdhoch:gap-8 hdhoch:gap-4 wqhdquer:gap-8 hdquer:gap-2"}>
      <h1 className={"wqhdhoch:text-8xl hdhoch:text-5xl hdquer:text-5xl font-bold grow"}>{menu.title}</h1>
      <h4 className={"wqhdhoch:text-6xl hdhoch:text-4xl hdquer:text-4xl"}>{menu.description}</h4>
      {menu.allergens !== null && (
        <h5 className={"wqhdhoch:text-4xl hdhoch:text-3xl hdquer:text-3xl italic text-gray-300 -mt-3"}>{menu.allergens}</h5>
      )}
    </div>
    {menu.price !== null && menu.price > 0 && (
      <h1 className={"wqhdhoch:text-8xl hdhoch:text-5xl hdquer:text-5xl w-44 wqhdhoch:w-80 wqhdquer:w-80 text-right"}>
        {formatEuro(menu.price)}
      </h1>
    )}
    {menu.deg && <MealDeg />}
  </div>
);

export default Meal;
