import React, {useEffect, useState} from "react";
import axios from "axios";

const Aktion = () => {
  const [data, setData] = useState<string>("");

  useEffect(() => {
    axios.get<string>("/aktion").then((response) => setData(response.data));
  }, [])

  return (
    <div className={"w-screen h-screen bg-no-repeat bg-cover bg-center fixed top-0 left-0 z-50"} style={{ backgroundImage: "url(" + data + ")" }} />
  )
}

export default Aktion;