import React, { ReactNode } from "react";
import board from "../assets/background-board.svg";

type PageWrapperProps = {
  children?: ReactNode;
}

const Pagewrapper = (props: PageWrapperProps) => (
  <div style={{ backgroundImage: "url(" + board + ")" }} className={"w-screen h-screen fixed bg-no-repeat bg-cover overflow-hidden"}>
    {props.children}
  </div>
)

export default Pagewrapper;