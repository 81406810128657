import React from "react";
import bowl from "../assets/bowls.svg";

const Bowl = () => (
  <img
    src={bowl}
    alt={"bowl"}
    className={"fixed -top-12 hdquer:-top-32 hdhoch:right-[240px] wqhdhoch:right-[500px] wqhdhoch:w-[450px] hdhoch:w-[250px]"}
  />
)

export default Bowl;